<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
<script setup lang="ts">
import { useUniqueID } from "@/composables/uniqueID";
import type { PropType } from "vue";

const props = defineProps({
  options: { type: Array as PropType<any[]>, required: true },
  name: { type: String, required: true },
  modelValue: { type: [String, Number, Object], default: undefined },
  vertical: { type: Boolean, default: false },
  error: { type: String, default: "" },
});

const uuid = useUniqueID().getID();
</script>

<template>
  <component
    v-for="(option, $optionIndex) of props.options"
    :key="$optionIndex"
    :class="[
      { 'ml-2': !props.vertical },
      {
        'card-container px-4 py-3 md:py-4 border-[1px] border-grayDetails-200 hover:border-2 hover:border-skin-major':
          option.icon,
      },
    ]"
    :is="props.vertical ? 'div' : 'span'"
    v-bind="{ ...$attrs }"
  >
    <BaseRadio
      :name="name"
      :label="typeof option === 'object' ? option.label : option"
      :modelValue="modelValue"
      :value="typeof option === 'object' ? option : option"
      :icon="option.icon"
      :subLabel="option.subLabel"
      @update:modelValue="$emit('update:modelValue', $event)"
    />
  </component>
  <BaseErrorMessage
    class="font-medium text-[15px] 2xl:text-base font-montserrat"
    v-if="props.error"
    :id="`${uuid}-error`"
  >
    {{ props.error }}
  </BaseErrorMessage>
</template>

<style scoped>
.card-container {
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 16px;
}
</style>
