import type { MaybeRef } from "@vueuse/core";
import { ref, type Component } from "vue";

import HomeIcon from "@/components/icons/HomeIcon.vue";
import PetIcon from "@/components/icons/PetIcon.vue";
import CommerceIcon from "@/components/icons/CommerceIcon.vue";
import CelIcon from "@/components/icons/CelIcon.vue";
import HealthIcon from "@/components/icons/HealthIcon.vue";
import MobilityIcon from "@/components/icons/MobilityIcon.vue";
import QuestionIcon from "../components/icons/QuestionIcon.vue";

const listIcon = [
  {
    name: "HG",
    component: HomeIcon,
  },
  {
    name: "RUS-HG",
    component: HomeIcon,
  },
  {
    name: "MA",
    component: PetIcon,
  },
  {
    name: "IC",
    component: CommerceIcon,
  },
  {
    name: "CL",
    component: CelIcon,
  },
  {
    name: "TE",
    component: QuestionIcon,
  },
  {
    name: "SA",
    component: HealthIcon,
  },
  {
    name: "BI",
    component: MobilityIcon,
  },
];

export const useIcon = (
  iconName: MaybeRef<string | null | undefined> = null
): Component => {
  const name = ref(iconName ?? null);
  const iconIndex = ref(0);

  iconIndex.value = listIcon.findIndex((icon) => icon.name === name.value);

  return listIcon[iconIndex.value]?.component ?? null;
};
