import { defineStore } from "pinia";
import {
  StorageSerializers,
  useStorage,
  type RemovableRef,
} from "@vueuse/core";

import type {
  ProductDetail,
  RequestDetail,
  Insured,
  Policy,
  PolicyCard,
} from "@/interfaces/product.interface";
import {
  fetchPolicies,
  fetchProductByID,
  fetchRequestDetail,
  fetchRequests,
} from "@/services/product.services";

interface ProductStore {
  policies: RemovableRef<Policy[]>;
  products: RemovableRef<PolicyCard[]>;
  currentPolicy: Policy;
  currentProduct: RemovableRef<PolicyCard | null>;
}

const handleError = (error: unknown) => {
  throw error;
};

export const useProductStore = defineStore("product", {
  state: (): ProductStore => ({
    policies: useStorage("policies", []),
    products: useStorage("products", []),
    currentPolicy: {} as Policy,
    currentProduct: useStorage<PolicyCard | null>(
      "currentProduct",
      null,
      undefined,
      {
        serializer: StorageSerializers.object,
      }
    ),
  }),

  getters: {
    insuredInfo(state): Insured {
      return state.currentPolicy.asegurado ?? {};
    },
    groupedPolicies(state) {
      const grouped = state.products.reduce((groups: any, policy: any) => {
        if (!groups[policy.product_cod_ramo_bco]) {
          groups[policy.product_cod_ramo_bco] = [];
        }
        groups[policy.product_cod_ramo_bco].push(policy);
        return groups;
      }, {});

      // Ordenar las keys alfabéticamente y reconstruir el objeto
      const sortedGroups = Object.keys(grouped)
        .sort()
        .reduce((sorted: any, key: string) => {
          sorted[key] = grouped[key];
          return sorted;
        }, {});

      return sortedGroups;
    },
  },

  actions: {
    setPolicies(policies: Policy[]): void {
      this.policies = policies;
    },

    setCurrentPolicy(policy: Policy | any): void {
      this.currentPolicy = policy;
    },

    setCurrentProduct(product: PolicyCard | any): void {
      this.currentProduct = product;
    },

    async loadProducts(): Promise<void> {
      try {
        const { data } = await fetchPolicies();
        this.products = data;
      } catch (error) {
        handleError(error);
      }
    },

    async loadProduct(id: string): Promise<ProductDetail> {
      const { data } = await fetchProductByID(id);
      this.setPolicies(data.polizas);
      return data;
    },

    async loadRequests() {
      try {
        const response = await fetchRequests();
        const data = response.data;
        return data;
      } catch (e) {
        console.log(e);
      }
    },

    async loadRequestDetail(id: string | number): Promise<RequestDetail> {
      const { data } = await fetchRequestDetail(id);
      return data;
    },
  },
});
